﻿import { Component, Input } from '@angular/core';


@Component({
    selector: 'stepper-footer',
    templateUrl: './stepperfooter.html',
    styles: []
})
export class StepperFooterComponent {
    @Input() screenOrderId: any;

    ngOnInit() {
    }
}
