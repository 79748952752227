import { Directive } from '@angular/core';
import { Validator, AbstractControl, NG_VALIDATORS } from '@angular/forms';

@Directive({
  selector: '[dateOfLossDirective]',
  providers: [{
    provide: NG_VALIDATORS,
    useExisting: DateOfLossDirective,
    multi: true
  }]
})
export class DateOfLossDirective implements Validator {
  validate(control: AbstractControl) : {[key: string]: any} | null {
    if (control.value && control.value.length > 0) {
        let date = new Date(control.value);
        let today = new Date();
        if(date > today)
            return { 'dateOfLossInvalid': true }; // return object if the validation is not passed.
        else
            return null;
    }
    return null; // return null if validation is passed.
  }
}