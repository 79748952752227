﻿import { Component, OnInit } from '@angular/core';
import { ClaimService } from '../../shared/claimservice';
import { ActivatedRoute, Params } from '@angular/router';


@Component({
    selector: 'stepper',
    templateUrl: './steppercontainer.html',
    styles: []
})
export class StepperContainerComponent implements OnInit{
    screen;
    screens: any[];
    totalNumberOfScreens;
    stepTitles: string[];
    constructor(private claimService: ClaimService, private route:ActivatedRoute){
        
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.screens = this.route.snapshot.data['steps'];
            this.screen = this.screens.find(s => s.screenOrderId == this.route.snapshot.params['id']);
            this.totalNumberOfScreens = this.screens.length;
            this.stepTitles = this.screens.map(function (s) { return s.name; })
        });
    }
}
