import { Component, Input } from '@angular/core'

@Component({
    selector: 'casualty-notice',
    template: `
                <p>NOTE: While your claim is being processed:</p>
                <ul>
                <li>Don't admit responsibility for the claim.</li>
                <li>Don't discuss the claim with anyone other than
                your insurance agent or authorized representative,
                TMHCC claims personal(can specify claims until
                personnel), or law enforcement officials.</li>
                <li>Don't give the copies of documents to anyone other
                than TMHCC claims personnel or authorized
                representatives.</li>
                <li>Don't destroy any documentation or other evidence
                that is applicable to the claim.</li>
`,
    styles: []
})
export class CasualtyNoticeComponent {
    
}