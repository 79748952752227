﻿import { Component, Input, OnInit, ViewChild, ElementRef, Inject, AfterViewInit } from '@angular/core';
import { ICanDeactivate } from '../stepper-route.deactivator.service';
import { JQ_TOKEN } from '../common/jQuery.service'
import { ToastrService } from '../common/toastr.service';
import { ClaimService } from '../../shared/claimservice';
import { Router, Params, ActivatedRoute, NavigationEnd } from '@angular/router';
import { DomSanitizer, SafeUrl, SafeResourceUrl } from '@angular/platform-browser';
import { trigger, transition, animate, style } from '@angular/animations';
import { Validators } from '@angular/forms';

@Component({
    selector: 'stepper-body',
    templateUrl: './stepperbody.html',
    styles: ['em{float:right; color:#E05C65;padding-left:10px}'],
    animations: [
        trigger('slideInOut', [
            transition(':enter', [
                style({ transform: 'translateY(-100%)' }),
                animate('500ms ease-in', style({ transform: 'translateY(0%)' }))
            ]),
            transition(':leave', [
                animate('500ms ease-in', style({ transform: 'translateY(-100%)' }))
            ])
        ])
    ]
})
export class StepperBodyComponent implements ICanDeactivate, OnInit {
    saved: boolean;
    currentStep: any;
    claimReferenceNum;
    fileModels: FieldModel[];
    filePreviewSrc: string | ArrayBuffer;
    filePreviews: string[] | ArrayBuffer;
    safeFilePreviewSrc: SafeResourceUrl[];
    filePreviewMessage: string;
    hasDocumentUpload: boolean = false;
    @Input() screen: any;
    screens: any;
    isSubmitStep: boolean;
    showDependentFields: Number[];
    hasDependentFields: boolean = false;
    parentFieldIds: Int32Array
    dependentFieldModels: DependentFieldModel[];
    acceptedFraudWarning: boolean = false;
    radioSelected: Number;
    validationMessage: String;
    modalTitle: String;
    formSubmissionInvalid: boolean;
    processing: boolean = false;
    @ViewChild('formGroupContainer') formGroupContainer: ElementRef;
    @ViewChild('formGroupContainer1') formGroupContainer1: ElementRef;
    @ViewChild('formGroupContainer2') formGroupContainer2: ElementRef;
    @ViewChild('formGroupContainer3') formGroupContainer3: ElementRef;
    companyName: String;
    policyNumber: any;
    zipCode: String;
    emailFieldName: String;
    emailMatchFieldName: String;
    fvWithChildren:FVWithChildren[];
    states:any[];
    presentScreen:any;
    
    constructor(private claimService: ClaimService, private router: Router, private route: ActivatedRoute, private domSanitizer: DomSanitizer,
        @Inject(JQ_TOKEN) private $: any) {
    }

    get claimId(): string {
        return this.claimService.claimId;
    }
    set claimId(value: string) {
        this.claimService.claimId = value;
    }

    get isClaimUpdate(): boolean {
        return this.claimService.isClaimUpdate;
    }
    set isClaimUpdate(value: boolean) {
        this.claimService.isClaimUpdate = value;
    }


    canDeactivate() {
        return true;
        return window.confirm('Discard changes?');
    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.fileSizeWarningMessage = [];
            this.states = this.claimService.getStates();
            this.currentStep = +this.route.snapshot.params['id'];
            this.screens = this.route.snapshot.data['steps'];
            if (localStorage.length > 0 && localStorage.getItem('companyName') != null && localStorage.getItem('companyName').length > 0) {
                this.companyName = localStorage.getItem('companyName');
            }
            else {
                var company = this.route.snapshot.data['steps'].find(s => s.screenOrderId == this.route.snapshot.params['id']).company;
                if (company) {
                    this.companyName = company.name;
                    localStorage.setItem('companyName', company.name);
                    this.claimService.logMessage('Company Name: ' + company.name).subscribe();
                }
            }

            if (sessionStorage.length > 0 && sessionStorage.getItem('claimRefNum') != null && sessionStorage.getItem('claimRefNum').length > 0) {
                this.claimId = sessionStorage.getItem('claimRefNum');
                this.claimReferenceNum = sessionStorage.getItem('claimRefNum');
            }

            this.isSubmitStep = this.currentStep === (this.screens.length + 1);
            let currentScreen = this.screens.filter(s => s.screenOrderId == this.currentStep)[0];
            this.presentScreen = this.screens.filter(s => s.screenOrderId == this.currentStep)[0];
            if (currentScreen) {
                let radioField = currentScreen.fields.find(f => f.fieldType == "Radio");
                if (radioField) {
                    if (radioField.fieldValue == null || radioField.fieldValue == '' || radioField.fieldValue == undefined){
                        let radioFieldDefaultValue = radioField.fieldValues.find(fv => fv.isDefault);
                        if (radioFieldDefaultValue && currentScreen.fields.some(f => f.fieldValue !== null || f.fieldValue !== '')) {
                            radioField.fieldValue = radioFieldDefaultValue.id;
                        }
                        else {
                            let radioFieldNonDefaultValue = radioField.fieldValues.find(fv => !fv.isDefault);
                            if (radioFieldNonDefaultValue) {
                                radioField.fieldValue = radioFieldNonDefaultValue.id;
                            }
                        }
                    }
                    else{
                        radioField.fieldValue = parseInt(radioField.fieldValue);
                    }
                }
                let emailField = currentScreen.fields.find(f => f.fieldType == "Email");
                if (emailField) {
                    this.emailFieldName = 'field-' + emailField.id;
                    this.emailMatchFieldName = 'fieldEmailMatch-' + emailField.id;
                }
                this.hasDocumentUpload = currentScreen.fields.some(f => f.fieldType === 'Document');
                this.hasDependentFields = currentScreen.fieldValueFields.length > 0;
            }

            if (this.hasDocumentUpload) {
                var fields = this.screens.filter(s => s.screenOrderId == this.currentStep)[0].fields.filter(f => f.fieldType === 'Document' && f.fieldValue !== null && f.fieldValue !== '');
                
                if (fields.length > 0) {
                    if (this.isClaimUpdate) {
                        this.safeFilePreviewSrc = new Array();
                    }
                    else {
                        this.filePreviews = new Array();
                    }
                    let i = 0;
                    for (let field of fields) {
                        if (this.fileModels === undefined)
                            this.fileModels = [];

                        let file = new FileToUpload();
                        file.fileAsBase64 = field.fieldValue;
                        if (this.fileModels.some(fm => fm.fieldId == field.id)) {
                            let fieldModel = this.fileModels.find(fm => fm.fieldId == field.id);
                            fieldModel.file = file;
                        }
                        else {
                            let fieldModel = new FieldModel();
                            fieldModel.fieldId = field.id;
                            fieldModel.file = file;
                            fieldModel.claimReferenceNumber = this.claimId;
                            this.fileModels.push(fieldModel);
                        }
                        
                        if (this.isClaimUpdate) {
                            
                            this.safeFilePreviewSrc[field.fieldOrderId - 1] = this.domSanitizer.bypassSecurityTrustResourceUrl('data: image / jpg; base64, ' + field.fieldValue);
                        }
                        else {
                            this.filePreviews[field.fieldOrderId - 1] = this.domSanitizer.bypassSecurityTrustResourceUrl('data: image / jpg; base64, ' + field.fieldValue);
                        }
                        i++;
                    }
                }
                else
                    this.filePreviews = new Array();
            }
            if (this.hasDependentFields) {
                var dependentFields = this.screens.filter(s => s.screenOrderId == this.currentStep)[0].fieldValueFields;

                if (dependentFields.length > 0) {
                    for (let field of dependentFields) {
                        if (this.dependentFieldModels === undefined)
                            this.dependentFieldModels = [];
                        if (this.dependentFieldModels.some(df => df.fieldId === field.fieldValueId)) {
                            this.dependentFieldModels.filter(df => df.fieldId === field.fieldValueId)[0].dependentFieldIds.push(field.fieldId);
                        }
                        else {
                            let dependentFieldModel = new DependentFieldModel();
                            dependentFieldModel.fieldId = field.fieldValueId;
                            dependentFieldModel.dependentFieldIds = [];
                            dependentFieldModel.dependentFieldIds.push(field.fieldId);
                            this.dependentFieldModels.push(dependentFieldModel);
                        }
                    }
                }
            }

            if (currentScreen && currentScreen.fields.some(f => f.dependentFieldId !== 0)) {
                let dependentFieldValue = 0;
                let fieldsThatAreDependent = this.screens.filter(s => s.screenOrderId == this.currentStep)[0].fields.filter(f => f.dependentFieldId !== 0);
                for (let field of fieldsThatAreDependent) {
                    let dependentFieldId = field.dependentFieldId;
                    for (let screen of this.screens) {
                        for (let field of screen.fields) {
                            if (field.id === dependentFieldId) {
                                dependentFieldValue = field.fieldValue;
                                if (dependentFieldValue !== 0 && dependentFieldValue !== null) {
                                    this.displayDependentFields(dependentFieldValue);
                                }
                            }
                        }
                    }
                }
            }

            if (currentScreen) {
                const result = [];
                const map = new Map();
                for (const item of currentScreen.fields) {
                    if(!map.has(item.id)){
                        map.set(item.id, true);    // set any value to Map
                        result.push({
                            id: item.id,
                            fieldType: item.fieldType,
                            isRequired: item.isRequired,
                            isPartialRequired: item.isPartialRequired,
                            screenId: item.screenId,
                            fieldOrderId: item.fieldOrderId,
                            fieldDescription: item.fieldDescription,
                            dependentFieldId: item.dependentFieldId,
                            fieldValues: item.fieldValues,
                            fieldValue: item.fieldValue,
                            entityId: item.entityId,
                            entityName: item.entityName
                        });
                    }
                }
                this.cloneFields(result);
            }

            if (currentScreen && currentScreen.fields.some(f => f.fieldType === 'Radio')) {
                var rbValue = this.screens.filter(s => s.screenOrderId == this.currentStep)[0].fields.filter(f => f.fieldType === 'Radio')[0].fieldValue;
                this.radioSelected = rbValue;
                if (rbValue !== null && rbValue !== undefined)
                    this.toggleDependentFieldsDefault(rbValue);
            }

            let dateField = currentScreen.fields.find(f => f.fieldType == "Date");
            if(dateField && dateField.fieldValue != null && dateField.fieldValue !== ''){
                var timestamp = Date.parse(dateField.fieldValue);
                var dateObject = new Date(timestamp);
                dateField.fieldValue = dateObject.toISOString().substring(0, 10);
            }

            this.addIndex = 1; 

            if (currentScreen && currentScreen.fields.some(f => f.fieldType === 'DropDown')) {
                let ddlFields = currentScreen.fields.filter(f => f.fieldType === 'DropDown');
                for(let ddlField of ddlFields){
                    if(ddlField.fieldValues.some(fv => fv.childfieldsandbehaviorattributes.length > 0)){   
                        if(this.fvWithChildren !== undefined){
                            let fieldValues = ddlField.fieldValues
                                                    .filter(fv => fv.childfieldsandbehaviorattributes.length > 0);
                            for(let fieldValue of fieldValues){
                                if(!this.fvWithChildren.some(fv => fv.id === fieldValue.id))
                                    this.fvWithChildren.push(fieldValue);
                            }                            
                        }
                        else{
                            this.fvWithChildren = ddlField.fieldValues
                                                    .filter(fv => fv.childfieldsandbehaviorattributes.length > 0);
                        }                 
                        
                    }
                }
            }
        });

        window.onbeforeunload = function () {
            localStorage.clear();
            return '';
        };
        
        this.router.events.subscribe((evt) => {
            if (!(evt instanceof NavigationEnd)) {
                return;
            }
            window.scrollTo(0, 0);
            
            setTimeout(() => 
            {
                this.$('input[type="text"]').first().focus();
            }, 1);
        });       
    }

    verifyRecaptcha: boolean = true;
    submitStepperForm(stepperForm, formValues) {
        this.$('#loader').show();
        if (stepperForm != null && stepperForm.invalid) {
            this.modalTitle = 'Missing';
            this.validationMessage = 'Missing required Information.';
            this.$('#simple-modal').modal({});
            this.formSubmissionInvalid = true;
            return;
        }
        else {
            this.formSubmissionInvalid = false;
        }
        if (!this.isClaimUpdate && this.screen.screenBehavior === 'Submit' && this.$('.custom-checkbox').length > 0 && !this.acceptedFraudWarning) {
            this.modalTitle = "Please Read";
            this.validationMessage = 'You must read and acknowledge the fraud warning.';
            this.$('#simple-modal').modal({});
            this.$('#loader').hide();
            return;
        }
        else if (this.screen.screenBehavior === 'Submit' && (this.acceptedFraudWarning || this.isClaimUpdate || this.$('.custom-checkbox').length == 0)) {
            let notificationEmails = [];
            let jQuery = this.$;
            this.$('input[type="Email"]').each(function (i, v) {
                notificationEmails.push(jQuery(this).val());
            });
            this.claimService.submitClaim(notificationEmails, this.isClaimUpdate).subscribe((data: any) => {
                this.modalTitle = 'Claim Submitted';
                this.validationMessage = data.message;
                this.$('#simple-modal').modal({});
                this.claimId = null;
                this.claimReferenceNum = null;
                localStorage.removeItem('claimRefNum');
                this.companyName = '';
                localStorage.removeItem('companyName');
                sessionStorage.removeItem('token');
                sessionStorage.removeItem('workflow');
                this.currentStep = 1;
                //this.router.navigate(['/steps/' + this.screens.filter(s => s.name === 'Validate')[0].screenOrderId]);
                this.router.navigate(['/']);
            }, (error) => {
                this.modalTitle = "Submit";
                this.validationMessage = error.error.title;
                this.$('#simple-modal').modal({});
                //console.log('Error submitting form: ' + error);
            });
        }
        else if (this.screen.screenBehavior === 'Update' && this.verifyRecaptcha) {
            if (this._captchaResponse === null) {
                this.modalTitle = 'Start Claim';
                this.validationMessage = 'Please verify that you’re a human.';
                this.$('#simple-modal').modal({});
                return;
            }
        }
        else if (this.screen.screenBehavior === 'Welcome') {
            for (var key in formValues) {
                if (formValues[key] !== null && formValues[key].indexOf !== undefined && formValues[key].indexOf('Popup:') !== -1) {
                    let phoneNumber = '800-467-8731';
                    if (this.companyName === 'Avemco Aviation')
                        phoneNumber = '800-874-9124';
                    this.modalTitle = 'Start Claim';
                    this.validationMessage = 'You cannot submit this claim online. Please call ' + phoneNumber + ' to report a new claim.';
                    this.$('#simple-modal').modal({});
                    return;
                }
            }
        }
        //else {
        if (formValues !== undefined && formValues != null) {
            //console.log('formValues ' + JSON.stringify(formValues));
            var fieldModels: any = [];
            for (var key in formValues) {
                //this part gets the existing claim id
                if (this.isClaimUpdate && this.claimReferenceNum === undefined) {
                    this.claimReferenceNum = formValues[key].toString().trim();
                    this.claimId = this.claimReferenceNum; // This is to pass claim id to the route resolver.
                };
                //this part gets the zipcode for validation
                if (this.isClaimUpdate && this.claimReferenceNum !== undefined && this.screen.screenBehavior !== 'DataEntry') {
                    this.zipCode = formValues[key].toString().trim();
                }
                if (this.screen.screenBehavior === 'Update' && !this.isClaimUpdate) {
                    let optionId = '#' + 'radio-' + formValues[key];
                    let optionDesc = this.$(optionId).attr('data-option-desc');
                    if (optionDesc !== undefined && optionDesc.indexOf('updating') !== -1) {
                        this.isClaimUpdate = true;
                    }
                }
                if (this.fileModels !== undefined && this.fileModels.some(fm => fm.fieldId == key.split('-').pop())) {
                    fieldModels.push(this.fileModels.find(fm => fm.fieldId == key.split('-').pop()));
                }
                else {
                    fieldModels.push({
                        fieldId: key.split('-').pop(),
                        fieldValue: formValues[key] !== undefined && formValues[key] !== null ? formValues[key].toString().trim() : '',
                        claimReferenceNumber: (this.claimId == null || this.claimId === '') ? 0 : this.claimId
                    });
                }
            }

            if (!this.isClaimUpdate || (this.isClaimUpdate && this.screen.name === 'Documents')) {
                if (this.screen.screenBehavior === 'Validation') {
                    this.claimService
                        .auth(fieldModels, this._captchaResponse)
                        .subscribe((data: any) => {
                            this.claimReferenceNum = data.id;
                            this.claimId = data.id;
                            localStorage.setItem('claimRefNum', data.id);
                            sessionStorage.setItem('token', data.token);
                            let nextStep = this.getNextStep(this.currentStep);
                            this.router.navigate(['/steps/' + (nextStep)]);
                        }
                            , (data) => {
                                this.modalTitle = 'Not Found';
                                this.validationMessage = 'Unable to find the policy reference number/insured zip code combination you have entered. Please reenter to try again. For further assistance contact us at 713-744-3704 or send an email to dgaray@tmhcc.com .'; // data.error.message;
                                this.$('#simple-modal').modal({});
                            })
                }
                else if (this.screen.screenBehavior === 'Update') {
                    //console.log('Going to next step ' + this.currentStep);
                    let nextStep = this.getNextStep(this.currentStep);
                    this.router.navigate(['/steps/' + (nextStep)]);
                }
                else {
                    let docFieldModels:any = [];
                    this.claimService
                        .saveStep(this.screen.name === 'Documents' ? docFieldModels : fieldModels, '')
                        .subscribe((data: any) => {
                            this.saved = true;
                            this.filePreviewSrc = undefined;
                            this.addP = this.addP1 = this.addP2 = this.addP3 = false;
                            let workflow = sessionStorage.getItem('workflow');
                            sessionStorage.setItem('workflow', data.workflow);
                            let nextStep = this.getNextStep(this.currentStep);
                            this.router.navigate(['/steps/' + (nextStep)]);
                        }, (error) => {
                            if (this.screen.screenBehavior === 'Validation') {
                                this.modalTitle = 'Policy Zip Code Match';
                                this.validationMessage = 'Could not find policy/zip code match. Please try again or call us at 800-467-8731.';
                                this.$('#simple-modal').modal({});
                            }
                            else {
                                this.modalTitle = 'Error';
                                let message = error.error !== null ? error.error : '';
                                if(error.error !== null && error.error.title)
                                    message = message + ' ' + error.error.title;
                                this.validationMessage = message;
                                this.$('#simple-modal').modal({});
                            }
                        });
                }
            }
            else if (this.isClaimUpdate && this.screen.screenBehavior === 'Update') {
                let claimRefNum = '';
                let controlNumber = this.claimId;
                if(this.claimId.indexOf('-') !== -1){
                    claimRefNum = this.claimId
                    controlNumber = '0';
                }
                this.claimService.validate(controlNumber, this.zipCode, this._captchaResponse, claimRefNum)
                    .subscribe((data: any) => {
                        this.claimId = data.claimReferenceNumber;
                        sessionStorage.setItem('token', data.token);
                        this.screens = data.screens;
                        let nextScreenId = this.screens.filter(s => s.name === 'Documents')[0].screenOrderId;
                        this.router.navigate(['/steps/' + nextScreenId]);
                }, (data) => {
                    this.modalTitle = 'Not Found';
                    this.validationMessage = 'Unable to find the claim reference number/insured zip code combination you have entered. Please reenter to try again. For further assistance contact us at 713-744-3704 or send an email to dgaray@tmhcc.com .'//data.error.message;
                    this.$('#simple-modal').modal({});
                });
            }
        }
        //}

        this.$('#loader').hide();
    }

    getNextStep(currentStep) {
        let workflow = sessionStorage.getItem('workflow');
        if(workflow != 'undefined' && workflow != null && workflow.length > 0){
            let workflowSteps = workflow.split(',');
            let currentStepIndex = workflowSteps.indexOf(currentStep.toString());
            let nextStepIndex = currentStepIndex + 1;
            if(workflowSteps[nextStepIndex] != null)
                return +workflowSteps[nextStepIndex];
        }
        let nextStep = currentStep + 1;
        if (nextStep >= this.screens.length) {
            return nextStep;
        }
        let returnNextStep = true;
        //if (this.screens.filter(s => s.screenOrderId == nextStep)[0].dependentFieldValueId > 0) {
        let nextScreen = this.screens.filter(s => s.screenOrderId == nextStep)[0];
        if (nextScreen.fieldValuesDependentScreens !== undefined && nextScreen.fieldValuesDependentScreens.length > 0) {
            //let depValueId = this.screens.filter(s => s.screenOrderId == nextStep)[0].dependentFieldValueId;
            let depValueIds = nextScreen.fieldValuesDependentScreens;
            for (let screen of this.screens) {
                for (let field of screen.fields.filter(f => f.fieldValues !== undefined && f.fieldValues !== null && f.fieldValues.length > 0)) {
                    //if (field.fieldValues.some(f => f.id === depValueId) && field.fieldValue == depValueId) {
                    if (field.fieldValue != null && field.fieldValues.some(f => depValueIds.some(dv => dv.dependentFieldValue == f.id)) && !depValueIds.some(dv => field.fieldValue == dv.dependentFieldValue)) {
                        returnNextStep = false;
                    }
                }
            }
        }
        else {
            return nextStep;
        }
        if (returnNextStep) {
            return nextStep;
        }
        return this.getNextStep(nextStep);
    }

    getPreviousStep(currentStep) {
        let workflow = sessionStorage.getItem('workflow');
        if(workflow != 'undefined' && workflow != null && workflow.length > 0){
            let workflowSteps = workflow.split(',');
            let currentStepIndex = workflowSteps.indexOf(currentStep.toString());
            let prevStepIndex = currentStepIndex - 1;
            if(workflowSteps[prevStepIndex] != null)
                return +workflowSteps[prevStepIndex];
        }
        let previousStep = currentStep - 1;
        if (previousStep <= 1) {
            return previousStep;
        }
        if (this.screens.filter(s => s.screenOrderId == previousStep)[0].dependentFieldValueId > 0) {
            let depValueId = this.screens.filter(s => s.screenOrderId == previousStep)[0].dependentFieldValueId;
            for (let screen of this.screens) {
                for (let field of screen.fields) {
                    if (field.fieldValues.some(f => f.Id === depValueId) && field.fieldValue === depValueId) {
                        return previousStep;
                    }
                }
            }
        }
        else {
            return previousStep;
        }
        return this.getPreviousStep(previousStep);
    }

    displayStartClaim() {
        //Using the popup element as the indicator to show/hide start claim for now. Need to change it to meaningful indicator if it's a permanent change.
        return true; //this.$('[id^="popup"]').length == 0;
    }

    displayContinue(){
        return (this.screen !== undefined && this.screen.screenBehavior !== undefined && this.screen.screenBehavior !== 'Submit' && this.screen.screenBehavior !== 'Welcome');
    }

    navigateToPreviousStep() {
        this.router.navigate(['/steps/' + this.getPreviousStep(this.currentStep)]);
    }

    navigateToSubmitStep() {
        this.router.navigate(['/steps/' + this.screens.length]);
    }

    submitClaim() {
        this.submitStepperForm(null, null)
    }

    reloadCurrentRoute() {
        let currentUrl = this.router.url;
        this.router.navigateByUrl('/', {skipLocationChange: true}).then(() => {
            this.router.navigate([currentUrl]);
        });
    }

    deleteEntity(entityId, entityName) {
        this.claimService.deleteEntity(entityId, entityName)
                            .subscribe((data: any) => {
                                if(data.isDeleted)
                                    this.reloadCurrentRoute();
                                else{
                                    this.modalTitle = "Delete";
                                    this.validationMessage = 'Unable to delete';
                                    this.$('#simple-modal').modal({});
                                }
                            }, (error) => {
                                this.modalTitle = "Delete";
                                this.validationMessage = error.error.title;
                                this.$('#simple-modal').modal({});
                                //console.log('Error submitting form: ' + error);
                            });
    }

    onFileChange(event, fId) {
        let index = event.target.attributes.getNamedItem('data-index').value;
        this.fileSizeWarningMessage[index] = '';
        if (event.target.files &&
            event.target.files.length > 0) {
            if (this.checkFileSize(event.target.files[0].size, index)) {
                this.fileSizeWarningMessage[index] = '';
                if (this.fileModels === undefined) {
                    this.fileModels = [];
                }

                if (!this.fileModels.some(fm => fm.fieldId == fId)) {
                    let fieldModel = new FieldModel();
                    fieldModel.fieldId = fId;
                    fieldModel.claimReferenceNumber = this.claimId == null ? 0 : this.claimId;
                    this.fileModels.push(fieldModel);
                }
                this.readFile(fId, event.target.files[0], event.target.attributes.getNamedItem('data-index').value);
            }
        }
    }

    private readFile(fieldId: any, theFile: any, index: any) {
        let file = new FileToUpload();

        // Set File Information
        file.fileName = theFile.name;
        file.fileSize = theFile.size;
        file.fileType = theFile.type;
        file.lastModifiedTime = theFile.lastModified;
        file.lastModifiedDate = theFile.lastModifiedDate;

        // Use FileReader() object to get file to upload
        // NOTE: FileReader only works with newer browsers
        let reader = new FileReader();

        // Setup onload event for reader
        reader.onload = () => {
            // Store base64 encoded representation of file
            file.fileAsBase64 = reader.result.toString();
            if (this.fileModels.some(fm => fm.fieldId == fieldId)) {
                let fieldModel = this.fileModels.find(fm => fm.fieldId == fieldId);
                fieldModel.file = file;
                let postFileModel = [fieldModel];
                this.claimService.saveStep(postFileModel, '').subscribe((data: any) => {
                    if (this.filePreviews !== undefined) {
                        if (file.fileType === 'application/pdf') {
                            this.filePreviews[index] = window.location.origin + '/assets/images/PDF_file_icon.svg';
                        }
                        else
                            this.filePreviews[index] = reader.result;
                    }
                }, (error) => {
                    let errMsg = 'no error';
                    if(error !== undefined && error !== null){
                        errMsg = 'error is not null';
                        if(error.error !== undefined && error.error !== null){
                            errMsg = 'error.error is not null';
                            errMsg = error.error;
                        }
                    }
                    this.modalTitle = 'Error';
                    this.validationMessage = 'This file may contain a virus, we are unable to upload. ' + errMsg;
                    this.$('#simple-modal').modal({});
                });
            }
            if (this.safeFilePreviewSrc !== undefined) {
                if (file.fileType === 'application/pdf') {
                    this.safeFilePreviewSrc[index] = window.location.origin + '/assets/images/PDF_file_icon.svg';
                }
                else
                    this.safeFilePreviewSrc[index] = reader.result;
            }
        }

        // Read the file
        reader.readAsDataURL(theFile);
        return file;
    }

    updateImageDisplay(event) {
        this.filePreviewMessage = 'File name ' + event.target.files[0].name + ', file size ' + this.getFileSize(event.target.files[0].size) + '.';
    }

    getFileSize(number) {
        if (number < 1024) {
            return number + 'bytes';
        } else if (number >= 1024 && number < 1048576) {
            return (number / 1024).toFixed(1) + 'KB';
        } else if (number >= 1048576) {
            return (number / 1048576).toFixed(1) + 'MB';
        }
    }

    fileSizeWarningMessage: String[] = [];
    checkFileSize(number, index) {
        if (number >= 1048576) {
            let fileSize = (number / 1048576);
            if (fileSize > 30) {
                this.fileSizeWarningMessage[index] = 'File too large: ' + fileSize.toFixed(1) + 'MB';
                return false;
            }
        }
        return true;
    }

    deleteDocument(event, fId) {
        let fieldModel = this.fileModels.find(fm => fm.fieldId == fId);
        fieldModel.file = null;
        fieldModel.claimReferenceNumber = this.claimId == null ? 0 : this.claimId;
        this.claimService.saveStep(this.fileModels, '').subscribe((data: any) => {
            let index = event.target.attributes.getNamedItem('data-index').value;
            delete this.filePreviews[index];
        }, (error) => {
            this.modalTitle = 'Error';
            this.validationMessage = error.error.title;
            this.$('#simple-modal').modal({});
        });
    }

    displayDependentFields(dependentFieldValue) {
        if (this.dependentFieldModels !== undefined) {
            var currentSelectedField = this.dependentFieldModels.filter(df => df.fieldId == dependentFieldValue)[0];
            if (currentSelectedField !== undefined) {
                var dependentFields = currentSelectedField.dependentFieldIds;
                if (dependentFields.length > 0) {
                    for (let dependentField of dependentFields) {
                        if (this.showDependentFields === undefined) {
                            this.showDependentFields = [];
                        }
                        if (this.showDependentFields.indexOf(dependentField) === -1)
                            this.showDependentFields.push(dependentField);
                    }
                }
            }
        }
    }

    private _prevSelected: any;
    toggleDependentFields(evt, stepperForm) {
        let target = evt.target;
        let currentSelectedTargetId = target.id.split('-').pop();
        if (target.checked) {
            if (this.dependentFieldModels !== undefined) {
                var prevDependentFields = [];
                var prevSelectedField = this.dependentFieldModels.filter(df => df.fieldId == this._prevSelected)[0];
                if (prevSelectedField !== undefined) {
                    prevDependentFields = prevSelectedField.dependentFieldIds;
                }
                if (prevDependentFields.length > 0) {
                    if (this.showDependentFields !== undefined) {                        
                        for (let prevDependentField of prevDependentFields) {
                            this.showDependentFields.splice(this.showDependentFields.indexOf(prevDependentField), 1);
                        }
                        let prevSelectedFieldId = '#radio-' + this._prevSelected;
                        let parentId = +this.$(prevSelectedFieldId).attr('data-parent-id');
                        let isCurrentSelectedFieldAlsoDependent = this.dependentFieldModels.some(df => df.dependentFieldIds.indexOf(parentId) !== -1);
                        if (isCurrentSelectedFieldAlsoDependent) {
                            this.showDependentFields.splice(this.showDependentFields.indexOf(parentId), 1);
                            let currentSelectedFieldId = '#radio-' + currentSelectedTargetId;
                            let currentSelectedParentId = +this.$(currentSelectedFieldId).attr('data-parent-id');
                            //reset it only if the radio option selected is not from the same set
                            if (currentSelectedParentId !== parentId)
                                stepperForm.controls['field-' + parentId].reset();
                        }
                    }
                }
                this._prevSelected = currentSelectedTargetId;
                var currentSelectedField = this.dependentFieldModels.filter(df => df.fieldId == target.id.split('-').pop())[0];
                if (currentSelectedField !== undefined) {
                    var dependentFields = currentSelectedField.dependentFieldIds;
                    if (dependentFields.length > 0) {
                        for (let dependentField of dependentFields) {
                            if (this.showDependentFields === undefined) {
                                this.showDependentFields = [];
                            }
                            if (this.showDependentFields.indexOf(dependentField) === -1)
                                this.showDependentFields.push(dependentField);
                        }
                        //Also, add the current selected field if it's visibility is dependent on another parent field.
                        let parentId = +target.attributes.getNamedItem('data-parent-id').value;
                        let isCurrentSelectedFieldAlsoDependent = this.dependentFieldModels.some(df => df.dependentFieldIds.indexOf(parentId) !== -1);
                        if (isCurrentSelectedFieldAlsoDependent) {
                            if (this.showDependentFields.indexOf(parentId) === -1)
                                this.showDependentFields.push(parentId);
                        }
                    }
                }
            }
        }
    }

    toggleDependentFieldsDefault(id) {
        if (this.dependentFieldModels !== undefined) {
            var prevDependentFields = [];
            var prevSelectedField = this.dependentFieldModels.filter(df => df.fieldId == this._prevSelected)[0];
            if (prevSelectedField !== undefined) {
                prevDependentFields = prevSelectedField.dependentFieldIds;
            }
            if (prevDependentFields.length > 0) {
                for (let prevDependentField of prevDependentFields) {
                    if (this.showDependentFields !== undefined) {
                        this.showDependentFields.splice(this.showDependentFields.indexOf(prevDependentField), 1);
                    }
                }
            }
            this._prevSelected = id;
            var currentSelectedField = this.dependentFieldModels.filter(df => df.fieldId == id)[0];
            if (currentSelectedField !== undefined) {
                var dependentFields = currentSelectedField.dependentFieldIds;
                if (dependentFields.length > 0) {
                    for (let dependentField of dependentFields) {
                        if (this.showDependentFields === undefined) {
                            this.showDependentFields = [];
                        }
                        if (this.showDependentFields.indexOf(dependentField) === -1)
                            this.showDependentFields.push(dependentField);
                    }
                }
            }
        }
    }

    getCircularReplacer = () => {
        const seen = new WeakSet();
        return (key, value) => {
            if (typeof value === "object" && value !== null) {
                if (seen.has(value)) {
                    return;
                }
                seen.add(value);
            }
            return value;
        };
    };

    isRequired(field, fieldId, stepperForm) {
        //if (stepperForm !== undefined && stepperForm.controls[fieldId] !== undefined && fieldId === 'field-7-12')
        //    console.log('stepper form: ' + JSON.stringify(stepperForm.controls[fieldId], this.getCircularReplacer()) + ' ' + fieldId);
        if (stepperForm !== undefined)
            return field.isRequired && stepperForm.controls[fieldId] !== undefined && stepperForm.controls[fieldId].errors !== null &&
                stepperForm.controls[fieldId].errors.required && (stepperForm.controls[fieldId].touched || this.formSubmissionInvalid);
        else
            return field.isRequired;
    }

    isPartialRequired(field, fieldId, stepperForm) {
        if (field.isPartialRequired && stepperForm !== undefined 
             && stepperForm.controls[fieldId] !== undefined 
             && (stepperForm.controls[fieldId].value == null || stepperForm.controls[fieldId].value == '')){
         let partialReqFields = this.presentScreen.fields.filter(f => f.isPartialRequired == true);
         let fieldIdParts = fieldId.split('-');
         let trueFieldId = fieldIdParts[fieldIdParts.length - 1];
         let isPartialRequired = field.isPartialRequired;
         let isPartialRuleRequired = false;
         for(let field of partialReqFields){
            if(field.id !== +trueFieldId){
                 let partialFieldId = '';
                 if(fieldIdParts.length === 4)
                     partialFieldId = 'field-' + fieldIdParts[1] + '-' + (field.fieldOrderId-1)  + '-' + field.id;
                 else
                     partialFieldId = 'field-' + (field.fieldOrderId-1)  + '-' + field.id;
                 if(stepperForm.controls[partialFieldId].value !== undefined 
                     && stepperForm.controls[partialFieldId].value !== null 
                     && stepperForm.controls[partialFieldId].value !== ''){
                     isPartialRequired = false;
                 }
                 else{
                     isPartialRequired = true;
                 }
             }
         }
         if(this.presentScreen.partialRequiredFieldRules !== undefined 
             && this.presentScreen.partialRequiredFieldRules.length > 0
             && this.presentScreen.partialRequiredFieldRules.some(f => f.requiredFieldId.toString() === trueFieldId)){
                 let rules = this.presentScreen.partialRequiredFieldRules.filter(f => f.requiredFieldId.toString() === trueFieldId);
                 for(let rule of rules){
                     if(rule.compareType === 'notnull'){
                         let presentScreenField = this.presentScreen.fields.filter(f => f.id === rule.fieldId)[0];
                         let ruleFieldId = '';
                         if(fieldIdParts.length === 4)
                             ruleFieldId = 'field-' + fieldIdParts[1] + '-' + (presentScreenField.fieldOrderId-1)  + '-' + rule.fieldId;
                         else
                             ruleFieldId = 'field-' + (presentScreenField.fieldOrderId-1)  + '-' + rule.fieldId;
                         let control = stepperForm.controls[ruleFieldId];
                         if(control.value !== undefined && control.value !== ''){
                             isPartialRuleRequired = true;
                             break;
                         }
                         else
                             isPartialRuleRequired = false;
                     }
                 }
                 let finalRequired = isPartialRequired && isPartialRuleRequired;
                 if(finalRequired){
                     stepperForm.controls[fieldId].setValidators([Validators.required]);
                     stepperForm.controls[fieldId].updateValueAndValidity();
                 }
                 else{
                     stepperForm.controls[fieldId].clearValidators([Validators.required]);
                     stepperForm.controls[fieldId].updateValueAndValidity();
                 }
                 return finalRequired;
             }
 
         return field.isPartialRequired ;
        }
         else
             return false;
     }

    isPhoneNumberMaxLengthExceeded(fieldId, stepperForm) {
        if (stepperForm !== undefined && stepperForm.controls !== undefined
            && stepperForm.controls[fieldId] !== undefined 
            && stepperForm.controls[fieldId].value != null 
            && stepperForm.controls[fieldId].value.length > 14)
            return true;
        return false;
    }

    isMaxLengthExceeded(fieldId, stepperForm) {
        if (stepperForm !== undefined && stepperForm.controls !== undefined
            && stepperForm.controls[fieldId] !== undefined 
            && stepperForm.controls[fieldId].value != null 
            && stepperForm.controls[fieldId].value.length > 500)
            return true;
        return false;
    }

    displayCharCount(fieldId, stepperForm) {
        if (stepperForm !== undefined && stepperForm.controls !== undefined
            && stepperForm.controls[fieldId] !== undefined 
            && stepperForm.controls[fieldId].value != null ){
                let chCount = 500 - stepperForm.controls[fieldId].value.length;
                return chCount;
            }
    }

    hasValue(fieldId, stepperForm) {
        return (stepperForm !== undefined && stepperForm.controls !== undefined
            && stepperForm.controls[fieldId] !== undefined 
            && stepperForm.controls[fieldId].value != null && stepperForm.controls[fieldId].value.length > 0)
    }

    isValidDate(field, fieldId, stepperForm) {
        if (stepperForm !== undefined)
            return stepperForm.controls[fieldId] !== undefined && stepperForm.controls[fieldId].errors !== null &&
                stepperForm.controls[fieldId].errors.dateOfLossInvalid && (stepperForm.controls[fieldId].dirty || this.formSubmissionInvalid);
        else
            return field.isRequired;
    }

    isValidPattern(field, fieldId, stepperForm) {
        if (stepperForm !== undefined)
            return stepperForm.controls[fieldId] !== undefined && stepperForm.controls[fieldId].errors !== null &&
                stepperForm.controls[fieldId].errors.pattern && (stepperForm.controls[fieldId].touched || this.formSubmissionInvalid);
        else
            return field.isRequired;
    }

    isValidPhonePattern(field, fieldId, stepperForm) {
        // if (stepperForm !== undefined && stepperForm.controls[fieldId] !== undefined){
        //     console.log('phone number length: ');
        //     console.log(stepperForm.controls[fieldId].value != null ? stepperForm.controls[fieldId].value.length : 0);
        // }
        //if (stepperForm !== undefined && stepperForm.controls[fieldId] !== undefined 
          //  && fieldId === 'field-7-14')
            //console.log('phone number length: ' + JSON.stringify(stepperForm.controls[fieldId].value, this.getCircularReplacer()));
        if (stepperForm !== undefined)
            return field.isRequired && stepperForm.controls[fieldId] !== undefined && stepperForm.controls[fieldId].errors !== null &&
                stepperForm.controls[fieldId].errors.pattern && (stepperForm.controls[fieldId].touched || this.formSubmissionInvalid);
        else
            return field.isRequired;
    }

    emailMatchModel: any = '';
    isMatching(field, fieldId, stepperForm) {
        //if (stepperForm !== undefined && stepperForm.controls[fieldId] !== undefined && fieldId === 'field-7-12')
        //    console.log('stepper form: ' + JSON.stringify(stepperForm.controls[fieldId].errors.pattern, this.getCircularReplacer()) + ' ' + fieldId);
        if (stepperForm !== undefined)
            return field.isRequired && stepperForm.controls[fieldId] !== undefined && stepperForm.controls[fieldId].errors !== null &&
                stepperForm.controls[fieldId].errors.mustMatch && (stepperForm.controls[fieldId].touched || this.formSubmissionInvalid);
        else
            return false;
    }

    isMobileScreen(){
        return window.screen.width <= 414;
      }

    displayField(fieldId, fieldValue) {
        if(this.fvWithChildren !== undefined && this.fvWithChildren.length > 0 && this.fvWithChildren.some(fv => fv.id === +fieldValue)){
            let fv = this.fvWithChildren.filter(fv => fv.id === +fieldValue)[0];
            for(let cf of fv.childfieldsandbehaviorattributes){
                let selector = '[id$="' + cf.fieldId + '"]';
                let forSelector = '[for$="' + cf.fieldId + '"]';
                switch(cf.behavior){
                    case 'show':
                        this.$(forSelector).show();
                        this.$(selector).show();
                        break;
                    case 'hide':
                        let childField = this.screen.fields.filter(f => f.id === cf.fieldId)[0]; 
                        //check and hide any child fields for this child fields
                        if(this.fvWithChildren.some(fv => fv.id === +childField.fieldValue)){
                            let cfv = this.fvWithChildren.filter(fv => fv.id === +childField.fieldValue)[0];
                            for(let gcf of cfv.childfieldsandbehaviorattributes){                                
                                let gcSelector = '[id$="' + gcf.fieldId + '"]';
                                let gcForSelector = '[for$="' + gcf.fieldId + '"]';
                                this.$(gcSelector).hide();
                                this.$(gcForSelector).hide();
                                let gChildField = this.screen.fields.filter(f => f.id === gcf.fieldId)[0];
                                gChildField.fieldValue = '';
                            }
                        }
                        this.$(forSelector).hide();
                        this.$(selector).hide();
                        //let field = this.screen.fields.filter(f => f.id === fieldId)[0]; 
                        //field.fieldValue = '';
                        childField.fieldValue = '';
                        break;
                    default:
                        break;
                }
            }
        }
        else if(this.fvWithChildren !== undefined && this.fvWithChildren.length > 0){
            for(let fv of this.fvWithChildren){
                if(fv.childfieldsandbehaviorattributes.some(cf => cf.fieldId === + fieldId)){
                    let parentSelector = '[id$="' + fv.fieldId + '"]';
                    let parentVal = this.$(parentSelector).val();
                    if(parentVal !== '' && fv.id === +parentVal){
                        let cf = fv.childfieldsandbehaviorattributes.filter(cf => cf.fieldId === + fieldId)[0];
                        switch(cf.behavior){
                            case 'show':
                                return true;
                                break;
                            case 'hide':
                                return false;
                                break;
                            default:
                                break;
                        }
                    }
                    else if(parentVal === '' || parentVal === null || parentVal === undefined)
                        return false;
                }
            }
        }

        if(fieldValue !== undefined && fieldValue !== null && fieldValue !== '' && fieldValue !== '0'){
            return true;
        }
        else{
            let isDependent = false;
            if (this.dependentFieldModels !== undefined) {
                isDependent = this.dependentFieldModels.some(df => df.dependentFieldIds.indexOf(fieldId) !== -1);
            }
            let isDependentAndShowField = (isDependent && this.showDependentFields !== undefined && this.showDependentFields.indexOf(fieldId) !== -1);
                   //let retVal = (!isDependent) || isDependentAndShowField || (isDependentAndShowField && (fieldValue !== undefined && fieldValue !== null && fieldValue !== ''));
                   return (!isDependent) || isDependentAndShowField || (isDependentAndShowField && (fieldValue !== undefined && fieldValue !== null && fieldValue !== ''));
        }
    }

    displayAdd() {
        return this.$('input[type="text"]').length > 0;
    }
    

    addIndex: number = 1
    addP: boolean = false;
    addP1: boolean = false;
    addP2: boolean = false;
    addP3: boolean = false;
    newFields: any;
    newFields1: any;
    newFields2: any;
    newFields3: any;
    cloneTemplate() {
        switch (this.addIndex) {
            case 1:
                this.addP = true;
                setTimeout(() => 
                {
                    this.formGroupContainer.nativeElement.style.borderTop  = '1px solid gray';
                    this.$('input[type="text"]', this.formGroupContainer.nativeElement).first().focus();
                }, 1);
                this.addIndex++;
                break;
            case 2:
                this.addP1 = true;
                setTimeout(() => 
                {
                    this.formGroupContainer1.nativeElement.style.borderTop  = '1px solid gray';
                    this.$('input[type="text"]', this.formGroupContainer1.nativeElement).first().focus();
                }, 1);
                this.addIndex++;
                break;
            case 3:
                this.addP2 = true;
                setTimeout(() => 
                {
                    this.formGroupContainer2.nativeElement.style.borderTop  = '1px solid gray';
                    this.$('input[type="text"]', this.formGroupContainer2.nativeElement).first().focus();
                }, 1);
                this.addIndex++;
                break;
            case 4:
                this.addP3 = true;
                setTimeout(() => 
                {
                    this.formGroupContainer3.nativeElement.style.borderTop  = '1px solid gray';
                    this.$('input[type="text"]', this.formGroupContainer3.nativeElement).first().focus();
                }, 1);
                this.addIndex++;
                break;
            default:
                break;
        }


        //console.log(this.formGroupContainer.nativeElement.querySelectorAll('.form-control'));
        //let elements = this.formGroupContainer.nativeElement.querySelectorAll('.form-control');
        //elements.forEach(element => {
        //    console.log(element);
        //    if (element.type === 'text') {
        //        let elementName = element.attributes['ng-reflect-name'];
        //        let newElementName = 'field-2-' + elementName.value.split('-').pop();
        //        let newElementId = 'txtField2' + elementName.value.split('-').pop();
        //        this.formGroupContainer.nativeElement.insertAdjacentHTML('beforeend',
        //            '<input _ngcontent-c2="" class="form-control form-control-sm ng-tns-c2-0 ng-trigger ng-trigger-slideInOut ng-untouched ng-pristine ng-star-inserted ng-invalid" type = "text" ng-reflect-required="true" ng-reflect-name="' + newElementName + '" id="' + newElementId + '" required>');
        //      }
        //    else if (element.type === 'textarea') {
        //        //this.formGroupContainer.nativeElement.insertAdjacentHTML('beforeend', '<input required [(ngModel)]="field.fieldValue" name="{{''field2 - '' + field.id}}" class="form-control form-control-sm" id="{{''txtField2'' + field.id}}" type="text" [@slideInOut] />< em * ngIf="field.isRequired && stepperForm.invalid" > Required < /em>');
        //    }
        //});
        //this.formGroupContainer.nativeElement.insertAdjacentHTML('beforeend', this.formGroupContainer.nativeElement.innerHTML);
    }

    deleteCloneTemplate(){
        if(this.addIndex > 1)
            this.addIndex--;
        switch (this.addIndex) {
            case 1:
                this.addP = false;
                break;
            case 2:
                this.addP1 = false;
                break;
            case 3:
                this.addP2 = false;
                break;
            case 4:
                this.addP3 = false;
                break;
            default:
                break;
        }
    }

    cloneFields(fields) {
        this.newFields = JSON.parse(JSON.stringify(fields));
        for (let field of this.newFields) {
            field.fieldValue = '';
            field.isRequired = false;
        }
        this.newFields1 = JSON.parse(JSON.stringify(fields));
        for (let field of this.newFields1) {
            field.fieldValue = '';
            field.isRequired = false;
        }
        this.newFields2 = JSON.parse(JSON.stringify(fields));
        for (let field of this.newFields2) {
            field.fieldValue = '';
            field.isRequired = false;
        }
        this.newFields3 = JSON.parse(JSON.stringify(fields));
        for (let field of this.newFields3) {
            field.fieldValue = '';
            field.isRequired = false;
        }
    }

    addFirstEmail: boolean = false;
    addSecondEmail: boolean = false;
    addEmailIndex: number = 1
    cloneEmailFields() {
        switch (this.addIndex) {
            case 1:
                this.addFirstEmail = true;
                this.addIndex++;
                break;
            case 2:
                this.addSecondEmail = true;
                this.addIndex++;
                break;
            default:
                break;
        }
    }

    _captchaResponse = null;
    resolved(captchaResponse: string) {
        this._captchaResponse = captchaResponse;
        //console.log(`Resolved captcha with response: ${captchaResponse}`);
    }
}

class FileToUpload {
    fileName: string = "";
    fileSize: number = 0;
    fileType: string = "";
    lastModifiedTime: number = 0;
    lastModifiedDate: Date = null;
    fileAsBase64: string = "";
}

class FieldModel {
    fieldId: string;
    fieldValue: string;
    claimReferenceNumber: number | string;
    file: FileToUpload;
}

class DependentFieldModel {
    fieldId: Number;
    dependentFieldIds: Number[];
}

class FVWithChildren {
    id: Number;
    fieldValue: string;
    isDefault: boolean;
    fieldId:Number;
    childfieldsandbehaviorattributes: Childfieldsandbehaviorattributes[];
}

class Childfieldsandbehaviorattributes {
    fieldVlaueId: Number;
    fieldId: Number;
    behavior: String;
}
