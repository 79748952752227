import { BrowserModule } from '@angular/platform-browser';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { NgModule, ElementRef, ErrorHandler } from '@angular/core';
import { CommonModule } from '@angular/common';
import { FormsModule, NG_VALIDATORS } from '@angular/forms';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { StepperHeaderComponent } from './stepper/stepperheader/stepperheader';
import { StepperFooterComponent } from './stepper/stepperfooter/stepperfooter';
import { StepperBodyComponent } from './stepper/stepperbody/stepperbody';
import { StepperContainerComponent } from './stepper/steppercontainer/steppercontainer';
import { ClaimService } from './shared/claimservice';
import { HttpClientModule } from '@angular/common/http';
import { RouterModule } from '@angular/router';
import { appRoutes } from '../routes';
import { Error404Component } from './stepper/errors/404.component';
import { StepperRouteActivator } from './stepper/stepper-route.activator.service';
import { StepperRouteDeActivator } from './stepper/stepper-route.deactivator.service';
import { StepperResolver } from './stepper/stepper.resolver.service';
import { ToastrService } from './stepper/common/toastr.service';
import { ClaimsNavComponent } from './nav/claimsnav.component';
import { SimpleModalComponent } from './stepper/common/simple-modal.component';
import { ModalTriggerDirective } from './stepper/common/modal-trigger.directive'
import { JQ_TOKEN } from './stepper/common/jQuery.service';
let jQuery = window['$'];
import { NgHttpLoaderModule } from 'ng-http-loader';
import { RecaptchaModule } from 'ng-recaptcha';
import { MustMatchDirective } from './directives/must-match.directive';
import { JwtModule } from "@auth0/angular-jwt";
import { PhoneMaskDirective } from './directives/phone-mask.directive';
import { DateOfLossDirective } from './validators/dateofloss.validator';
import { GlobalErrorHandler } from './shared/global-error-handler';
import { CasualtyNoticeComponent } from './stepper/common/casualty-notice.component';

export function tokenGetter() {
    return sessionStorage.getItem("token");
}

@NgModule({
  declarations: [
        AppComponent,
        StepperContainerComponent,
        StepperHeaderComponent,
        StepperFooterComponent,
        StepperBodyComponent,
        Error404Component,
        ClaimsNavComponent,
        SimpleModalComponent,
        ModalTriggerDirective,
        MustMatchDirective,
        PhoneMaskDirective,
        DateOfLossDirective,
        CasualtyNoticeComponent
    ],
  imports: [
      BrowserModule,
      BrowserAnimationsModule,
        AppRoutingModule,
        CommonModule,
      HttpClientModule,
      FormsModule,
      RecaptchaModule,
      NgHttpLoaderModule.forRoot(),
      RouterModule.forRoot(appRoutes),
      JwtModule.forRoot({
          config: {
              tokenGetter: tokenGetter,
              whitelistedDomains: ["claimsappapi.tmhcc.com"],
              blacklistedRoutes: []
          }
      })
    ],
    providers: [ClaimService, StepperRouteActivator, StepperRouteDeActivator, StepperBodyComponent, StepperResolver, ToastrService,
        { provide: JQ_TOKEN, useValue: jQuery },
        {
          provide: NG_VALIDATORS,
          useExisting: DateOfLossDirective,
            multi: true
        }, 
        {provide: ErrorHandler, useClass: GlobalErrorHandler}],
  bootstrap: [AppComponent]
})
export class AppModule { }
