﻿import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector: 'stepper-header',
    templateUrl: './stepperheader.html',
    styles: ['ClientApp/assets/styles/stepper.scss']
})
export class StepperHeaderComponent implements OnInit {
    @Input() totalStepCount: any;
    @Input() stepTitles: string[];
    companyName: string = 'Report a Claim';
    steps: any[];
    currentStep: any;
    constructor(private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            this.steps = Array(this.totalStepCount).fill(this.totalStepCount).map((x, i) => i);
            this.currentStep = params['id'];

            var currentScreen = this.route.snapshot.data['steps'].find(s => s.screenOrderId == this.route.snapshot.params['id']);
            this.companyName = 'Report a Claim';
            if (currentScreen) {
                var company = currentScreen.company;
                if (company) {
                    this.companyName = company.banner + ' Claim Report';
                }
            }
        });
    }   
}
