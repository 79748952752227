﻿import { Injectable } from '@angular/core';
import { StepperBodyComponent } from './stepperbody/stepperbody';
import { CanDeactivate } from '@angular/router/src/utils/preactivation';
import { Observable } from 'rxjs';


export interface ICanDeactivate {
    canDeactivate(): Observable<boolean> | Promise<boolean> | boolean;
}

@Injectable({
    providedIn: 'root'
})
export class StepperRouteDeActivator implements CanDeactivate{
    component;
    route;

    constructor(component: StepperBodyComponent) {
        this.component = component;
    }

    canDeactivate(component: ICanDeactivate) {  
        return this.component.canDeactivate();
    }
}