import { Component } from '@angular/core';

@Component({
  selector: 'app-root',
  templateUrl: './stepper.html',
    styles: ['ClientApp/assets/styles/Styles.css']
})
export class AppComponent {
    title = 'claims-app';
}
