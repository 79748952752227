﻿import { Injectable } from '@angular/core';
import { Resolve } from '@angular/router';
import { ClaimService } from '../shared/claimservice';
import { map } from 'rxjs/operators';

@Injectable()
export class StepperResolver implements Resolve<any>{
    constructor(private claimService: ClaimService) {

    }

    resolve() {
        return this.claimService.getScreens().pipe(map(steps => steps));
    }
}