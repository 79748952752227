import { ErrorHandler, Injectable, Injector } from '@angular/core';
import { ClaimService } from './claimservice';
import { ErrorService } from './error-service';
import { HttpErrorResponse } from '@angular/common/http';

@Injectable()
export class GlobalErrorHandler implements ErrorHandler {
  
    // Error handling needs to be loaded first.
    // Because of this we should manually inject the services with Injector.
    constructor(private injector: Injector){}

    handleError(error: Error) {
        const claimService = this.injector.get(ClaimService);
        const errorService = this.injector.get(ErrorService);
        let message;
        let stackTrace;
        if (error instanceof HttpErrorResponse) {
            // Server Error
            message = errorService.getServerMessage(error);
            stackTrace = errorService.getServerStack(error);
        } else {
            // Client Error
            message = errorService.getClientMessage(error);
            stackTrace = errorService.getClientStack(error);
        }   
        claimService.logMessage('message: ' + message + ', stackTrace: ' + stackTrace); 
    }
}