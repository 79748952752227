﻿import { Component } from '@angular/core';
import { ActivatedRoute, Params } from '@angular/router';

@Component({
    selector:'claims-nav',
    templateUrl:'./claimsnav.html'
})
export class ClaimsNavComponent {
    companyLogo: string;
    constructor(private route: ActivatedRoute) {

    }

    ngOnInit() {
        this.route.params.subscribe((params: Params) => {
            if (this.route.snapshot.data['steps'] !== undefined) {
                var currentScreen = this.route.snapshot.data['steps'].find(s => s.screenOrderId == this.route.snapshot.params['id']);
                this.companyLogo = '';

                if (currentScreen) {
                    var company = currentScreen.company;
                    if (company) {
                            this.companyLogo = company.logo;
                    }
                }
            }
        });
    }   
}