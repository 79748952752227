﻿import { Injectable } from '@angular/core';
import { Router, CanActivate } from '@angular/router';
import { JwtHelperService } from '@auth0/angular-jwt';

@Injectable()
export class StepperRouteActivator implements CanActivate{
   
    constructor(private router: Router, private jwtHelper: JwtHelperService) {

    }

    canActivate() {  
        let token = sessionStorage.getItem("token");
        let claimId = sessionStorage.getItem("claimRefNum");
        if (claimId) {
            if (token && !this.jwtHelper.isTokenExpired(token)) {
                return true;
            }
            this.router.navigate(["/"]);
            return false;
        }
        return true;
    }
}