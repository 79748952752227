﻿import { StepperContainerComponent } from './app/stepper/steppercontainer/steppercontainer';
import { Error404Component } from './app/stepper/errors/404.component';
import { StepperRouteActivator } from './app/stepper/stepper-route.activator.service';
import { StepperRouteDeActivator } from './app/stepper/stepper-route.deactivator.service';
import { StepperResolver } from './app/stepper/stepper.resolver.service';

export const appRoutes = [
    { path: 'Claims', redirectTo: '/steps/1', pathMatch: 'full' },
    {
        path: 'steps/:id', component: StepperContainerComponent,
        canDeactivate: [StepperRouteDeActivator],
        resolve: { steps: StepperResolver },
        canActivate: [StepperRouteActivator]
    },
    { path: '', redirectTo: '/steps/1', pathMatch: 'full' },
    { path: '404', component: Error404Component },
    { path: '**', component: Error404Component }
]